.mode {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background-color: #FCF6F6;
}

.mode h1 {
    font-family: "dream-avenue";
    font-size: 50px;
    text-align: center;
}

.or-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .or {
    font-size: 25px;
    font-weight: 600;
    position: relative;
    padding: 0 10px; /* Adjust this padding if necessary */
    z-index: 1;
  }
  
  .or:before,
  .or:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 230%;
    height: 1.5px;
    background-color: black;
    z-index: -1; /* Places the lines behind the text */
  }
  
  .or:before {
    left: -250%; /* Extend to the left */
  }
  
  .or:after {
    right: -250%; /* Extend to the right */
  }

  .kpop-button {
    background-color: #97444A;
    color: white;
  }
  
  .western-button {
    background-color: #F6C9CC;
    color: black;
  }

  .back {
    align-self: flex-start;
    border: none; 
    background: none;
    cursor: pointer;
    width: 20%;
    position: absolute;
    top: 30px;
    font-size: 40px;
  }