.splash {
    background: #97444A;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
}

.splash p {
    color: white;
}