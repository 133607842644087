/* Reset default margin and padding */
html, body {
    margin: 0;
    padding: 0;
  }
  

  /* Ensure html and body are full height */
  html, body {
    height: 100%;
    width: 100%;
    overflow: hidden; /* Prevent scrolling */
  }
  

  
  .App-header {
    text-align: center;
  }

.game-button {
  background-color: #97444A;
  color: white;
}

.badges-button {
  background-color: #F6C9CC;
  color: black;
}

button {
  width: 344px;
height: 85px;
border: none;
border-radius: 15px;
font-size: 25px;
font-family: "open-sans";
font-weight:650;
text-transform: uppercase;
}

img {
  padding: 0;
  width: 335px;
}

p {
  font-family: "open-sans";
}