.mic {
    font-size: 65px;
    border: none; 
    border-radius: 50%;
    background: #7D3339;
    text-align:center;
    cursor: pointer;
    width: 110px;
    height: 110px;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    text-align: center;
    outline: none;
}