.game-screen {
    display:flex;
    align-items: center;
    justify-content: center;
}

.game {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background-color: #FCF6F6;
}

.game h1 {
    font-family: "dream-avenue";
    font-size: 50px;
    margin-bottom: 0;
}

.game p {
    text-align:center;
}

.game-container {
    display:flex;
    align-items: center;
    justify-content: center;
}

.random-photo {
    width: calc(52% - 20px);
    margin: 10px;
    box-sizing: border-box;
    border: 5px solid #7D3339;
    display: flex;
    justify-content: center;
    align-items: center;
}

.random-photo img {
    width: 100%;
    height: auto;
}

.round {
    color: black; 
    background-color: #F6C9CC;
}