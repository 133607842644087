@import url(./styles/HomeScreen.css);

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FCF6F6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  box-sizing:border-box;
  padding: 10px;
  width: 100%
}

/* Adjustments for various screen sizes */
/* Medium devices (large phones, 375px and up) */
@media (min-width: 376px) {
  .App-header {
    padding: 5% 10%; /* Adjust percentage as needed */
  }
}

/* Small devices (phones, less than 375px) */
@media (max-width: 375px) {
  .App-header {
    padding: 5% 5%; /* Reduce padding for very small screens */
    width: 100vw;
  height: 100vh;
  transform: scale(0.9);
  transform-origin: center;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
